import { OAuthAccountListing, OAuthAccountListingForMultiple } from '@pages/dataSource';
import { capitalizeSourceName } from '@utils';
import { UPPERCASE_DATA_SOURCES } from '@constants';

export function NoDataDisplay() {
  return (
    <div className='h-available flex h-[6.9375rem] items-center justify-center text-sm font-normal leading-[1.0588rem] text-tintGray xxl:h-[11.6875rem]'>
      No data to be displayed here
    </div>
  );
}

export function AccountField({
  showProperties,
  accountPropertiesListing,
  checkedPropertyId,
  setCheckedPropertyId,
  setCheckedProperty,
  oAuthListing,
  linkedInAccounts,
  handleLinkedInAccountsChecked,
  handleMultipleChecked,
  dataSource,
  fbAccountListing,
  accountListing,
  checkedAccountId,
  setCheckedAccountId,
  setCheckedAccount,
  typeFormAccountListing,
  showMultiChecks,
  checkedIds,
  instagramPagesListing,
}) {
  const renderOAuthAccountListing = (listing, checkedId, setCheckedId, setCheckedAccount) => (
    <OAuthAccountListing
      listing={listing}
      checkedId={checkedId}
      setCheckedId={setCheckedId}
      setCheckedAccount={setCheckedAccount}
      dataSource={dataSource}
    />
  );

  /**
   * Retrieves the appropriate listing based on the provided data source name.
   *
   * This function maps the given data source name to its corresponding listing.
   * If the data source name is not recognized, it returns an empty array.
   *
   * @param {string} sourceName - The name of the data source.
   * @returns {Array} The listing corresponding to the provided data source name, or an empty array if the name is not recognized.
   */

  const getListingBySourceName = sourceName => {
    const listings = {
      [UPPERCASE_DATA_SOURCES.LINKEDIN_ADS]: linkedInAccounts,
      [UPPERCASE_DATA_SOURCES.TYPEFORM]: typeFormAccountListing?.data?.items,
    };

    return listings[sourceName] || [];
  };

  const getHandleCheckBySourceName = sourceName => {
    const handleCheckFunctions = {
      [UPPERCASE_DATA_SOURCES.LINKEDIN_ADS]: handleLinkedInAccountsChecked,
    };

    return handleCheckFunctions[sourceName] || handleMultipleChecked;
  };

  const renderContent = () => {
    if (showProperties) {
      if (accountPropertiesListing?.data?.properties?.length > 0) {
        return renderOAuthAccountListing(
          accountPropertiesListing?.data?.properties,
          checkedPropertyId,
          setCheckedPropertyId,
          setCheckedProperty
        );
      } else {
        return <NoDataDisplay />;
      }
    } else if (oAuthListing?.data?.results.length > 0 && !showMultiChecks) {
      const listing =
        capitalizeSourceName(dataSource?.sourceName) === UPPERCASE_DATA_SOURCES.FACEBOOK_MARKETING
          ? fbAccountListing?.data?.accounts
          : capitalizeSourceName(dataSource?.sourceName) === UPPERCASE_DATA_SOURCES.INSTAGRAM
            ? instagramPagesListing?.data?.pages
            : accountListing?.data?.accounts;

      return renderOAuthAccountListing(listing, checkedAccountId, setCheckedAccountId, setCheckedAccount);
    } else if (showMultiChecks) {
      return (
        <OAuthAccountListingForMultiple
          checkedIds={checkedIds}
          listing={getListingBySourceName(capitalizeSourceName(dataSource?.sourceName))}
          linkedinDataSource={capitalizeSourceName(dataSource?.sourceName) === UPPERCASE_DATA_SOURCES.LINKEDIN_ADS}
          handleCheck={getHandleCheckBySourceName(capitalizeSourceName(dataSource?.sourceName))}
        />
      );
    } else {
      return <NoDataDisplay />;
    }
  };

  return <div className='flex-grow'>{renderContent()}</div>;
}
